<template>
    <div id="home">
        <div class="home" v-if="data.banner">
            <!-- 轮播图 -->
            <div class="swiper">
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in data.banner" :key="item.id">
                        <van-image :src="item.pic_url"  @click="hrefUrl(item.id)" :lazy-load="true"></van-image>
                    </van-swipe-item>
                </van-swipe>
                <!-- <div class="buy-preson">234人以抢</div> -->
            </div>

            <!-- 精品推荐 -->

            <div class="recom" v-if="data.goods_info[0] && data.goods_info[0].goods.length > 0">
                <van-cell value="查看更多" :title="data.goods_info[0].cate.title" is-link @click="mone('/recom')"></van-cell>
                <van-swipe :loop="false" :width="182" :show-indicators="false">
                    <van-swipe-item v-for="item in data.goods_info[0].goods" :key="item.id">
                        <router-link class="recom-list" :to="{ path: `/detail/${item.id}` }" @click="orderIdClick(item.id)">
                            <van-image :src="item.thumb_pic" height="175px" :lazy-load="true" />
                            <div class="recom-item-info">
                                <div class="title">{{ item.title }}</div>
                                <div class="money">
                                    <div>
                                        ¥<span style="font-size: 17px">{{ item.price.split(".")[0] }}</span
                                        >.<span>{{ item.price.split(".")[1] }}</span>
                                    </div>
                                    <div style="font-weight: 400;">¥{{ item.price_show }}</div>
                                </div>
                                <div class="buy">
                                    <div style="display:flex;align-items:center">
                                        <van-icon name="friends-o" size="18" />
                                        <span>{{ item.buy_num }}人已抢</span>
                                    </div>
                                    <van-button size="mini" color="#EC2627">立即抢购</van-button>
                                </div>
                            </div>
                        </router-link>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <!-- 今日秒杀 -->
            <div class="recom" v-if="data.goods_info[1] && data.goods_info[1].goods.length > 0">
                <van-cell value="查看更多" :title="data.goods_info[1].cate.title" is-link @click="mone('/seckill')"></van-cell>
                <van-swipe :loop="false" :width="182" :show-indicators="false">
                    <van-swipe-item v-for="item in data.goods_info[1].goods" :key="item.id">
                        <router-link class="recom-list" :to="{ path: `/detail/${item.id}` }" @click="orderIdClick(item.id)">
                            <van-image :src="item.thumb_pic" height="175px" :lazy-load="true" />
                            <div class="recom-item-info">
                                <div class="title">{{ item.title }}</div>
                                <div class="money">
                                    <div>
                                        ¥<span style="font-size: 17px">{{ item.price_pin.split(".")[0] }}</span
                                        >.<span>{{ item.price_pin.split(".")[1] }}</span>
                                    </div>
                                    <div style="font-weight: 400;">¥{{ item.price }}</div>
                                </div>
                                <div class="buy">
                                    <div style="display:flex;align-items:center">
                                        <span>
                                            <van-count-down :time="item.pin_end_time | timer">
                                                <template #default="timeData">
                                                    <span class="block">{{ timeData.days }}</span>
                                                    <span class="colon">:</span>
                                                    <span class="block">{{ timeData.hours }}</span>
                                                    <span class="colon">:</span>
                                                    <span class="block">{{ timeData.minutes }}</span>
                                                    <span class="colon">:</span>
                                                    <span class="block">{{ timeData.seconds }}</span>
                                                </template>
                                            </van-count-down>
                                        </span>
                                    </div>
                                    <van-button size="mini" color="#EC2627">立即抢购</van-button>
                                </div>
                            </div>
                        </router-link>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <!-- 团购 -->

            <div class="recom" v-if="data.goods_info[2] && data.goods_info[2].goods.length > 0">
                <van-cell value="查看更多" :title="data.goods_info[2].cate.title" is-link @click="mone('/groupbuy')"></van-cell>
                <van-swipe :loop="false" :width="182" :show-indicators="false">
                    <van-swipe-item v-for="item in data.goods_info[2].goods" :key="item.id">
                        <router-link class="recom-list" :to="{ path: `/detail/${item.id}` }" @click="orderIdClick(item.id)">
                            <van-image :src="item.thumb_pic" height="175px" :lazy-load="true" />
                            <div class="recom-item-info">
                                <div class="title">{{ item.title }}</div>
                                <div class="money">
                                    <div>
                                        ¥<span style="font-size: 17px">{{ item.price_pin.split(".")[0] }}</span
                                        >.<span>{{ item.price_pin.split(".")[1] }}</span>
                                    </div>
                                    <div style="font-weight: 400;">¥{{ item.price }}</div>
                                </div>
                                <div class="buy">
                                    <div style="display:flex;align-items:center">
                                        <van-icon name="friends-o" size="18" />
                                        <span>{{ item.buy_num }}人成团</span>
                                    </div>
                                    <van-button size="mini" color="#EC2627">立即抢购</van-button>
                                </div>
                            </div>
                        </router-link>
                    </van-swipe-item>
                </van-swipe>
            </div>

            <!-- 发现特价 -->
            <div class="special-offer" v-if="data.goods_info[3] && data.goods_info[3].goods.length > 0">
                <van-cell value="查看更多" :title="data.goods_info[3].cate.title" is-link @click="mone('/items')"></van-cell>
                <div class="special-list">
                    <goods-list :data="data.goods_info[3].goods" @click="hrefUrl2"></goods-list>
                </div>
            </div>
        </div>
        <van-empty image="https://khome2.tuzuu.com/klfront/newlogo.png" description="正在加载..." v-else />
        <tab-bar :active="0"></tab-bar>
    </div>
</template>

<script>
import TabBar from "@/components/tabbar";
import GoodsList from "@/components/GoodsList";
import taskAxios from "@/http/task";

export default {
    created() {
        axios.get("/goods_index").then(res => {
            console.log(res)
            this.data = res.data;
        });

        const query = this.$route.query;
        if (query.task_userid && query.taskid && query.scan_time) {
            this.timer = setTimeout(() => {
                taskAxios
                    .post("/done_view_level_task", {
                        task_userid: query.task_userid,
                        task_id: query.taskid,
                    })
                    .then(res => {
                        this.$toast("任务完成");
                        this.$router.replace({
                            path: "/home",
                        });
                    });
            }, query.scan_time * 1000);
        }
    },
    data() {
        return {
            data: { goods_info: [{ cate: { title: "" } }, { cate: { title: "" } }] },
        };
    },
    components: {
        TabBar,
        GoodsList,
    },
    methods: {
        orderIdClick(id) {
            window._czc.push(["_trackEvent", "点击商品", "商品id", , id]);
        },
        hrefUrl(url) {
            window._czc.push(["_trackEvent", "点击商品", "商品id", , url]);
            this.$router.push({ path: `/detail/${url}` });
        },
        hrefUrl2(goods) {
            this.$router.push({ path: `/detail/${goods.id}` });
        },
        mone(url) {
            this.$router.push({ path: url });
        },
    },
    filters: {
        timer(value) {
            // "2021-02-06 00:00:00"
            let arr1 = value.slice(0, 10).split("-");
            let arr2 = value.slice(11).split(":");
            let nowDate = new Date().getTime();
            let endDate = new Date(arr1[0], parseInt(arr1[1]) - 1, arr1[2], arr2[0], arr2[1], arr2[2]).getTime();
            return endDate - nowDate;
        },
    },
    beforeRouteLeave(to, from, next) {
        clearTimeout(this.timer);
        next();
    },
};
</script>

<style lang="less" scoped>
.home {
    padding-bottom: 80px;
    /deep/ .van-image__img {
        object-fit: fill;
    }
}
#home {
    height: 100%;
}

.van-empty {
    height: 100%;
    /deep/.van-empty__description {
        color: rgb(142, 205, 88);
        font-size: 15px;
        margin: 0;
    }
}
/deep/.van-image__img {
    border-radius: 5px;
    object-fit: cover;
}
.buy-preson {
    color: #c3b5c3;
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    text-align: center;
}
.van-cell {
    padding: 10px 0px;
    &::after {
        display: none;
    }
    .van-cell__right-icon {
        height: 22px;
        line-height: 22px;
    }
}
.van-cell__title {
    font-weight: 700;
    font-size: 16px;
    color: #232323;
}
.van-cell__value {
    color: #999;
    font-size: 13px;
}
.recom {
    padding: 0px 10px;
    box-sizing: border-box;
    .recom-list {
        display: flex;
        flex-direction: column;
        color: black;
        margin-right: 10px;
        font-family: SourceHanSansSC-regular;
        .recom-item-info {
            margin-top: 10px;
        }
        /deep/ .van-image__img {
            box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
        }
    }
}
.title {
    color: #232323;
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.money {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 5px;
    color: #ec2627;
    font-weight: 700;
    div:first-child {
        font-size: 9px;
    }
    div:last-of-type {
        color: #ccc;
        font-size: 12px;
        text-decoration: line-through;
    }
}
.buy {
    font-size: 12px;
    margin-top: 5px;
    color: #999;
    display: flex;
    justify-content: space-between;
    .colon {
        display: inline-block;
        margin: 0 4px;
        color: #ee0a24;
    }
    .block {
        display: inline-block;
        width: 18px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #ee0a24;
    }
}

.special-offer {
    padding: 0px 10px;
    box-sizing: border-box;
    margin-top: 5px;
    /deep/ .van-card {
        padding: 9px 0px;
    }
}
</style>
